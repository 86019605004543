$primary: #008F85;
$secondary: #004251;
$dark: #000A18;
$lightGray: #F8F9FA;
$gray: #E6E6E6;
$darkGray: #808080;
$yellow: #FFC000;
$danger: #CD2C2C;
$darkRed: #500200;
$black: #121212;
$white: white;
$blue: #00B0FF;
$darkBlue: #1B2D5B;
$color-blind: #19168c;

// change bootstrap default theme
$theme-colors: (
  "primary": $primary,
  'secondary': $secondary,
  "danger": $danger,
  'dark': $dark,
  'light-gray': $lightGray,
  'gray': $gray,
  'dark-gray': $darkGray,
  "yellow": $yellow,
  'dark-red': $darkRed,
  'blue': $blue,
  'dark-blue': $darkBlue,
  'black': $black,
  'white': $white,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// ArcGIS basemap css
@import '@arcgis/core/assets/esri/themes/light/main.css';
@import "https://js.arcgis.com/4.27/esri/themes/light/main.css";

/* 
  Customize global className variable 
*/

// Center a component in a div
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Center a component vertically in a div
.center-y {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Add shadow below the component
.shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

// Add shadow to the right side of the component
.shadow-right {
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.15);
}

// Pointer cursor
.pointer {
  cursor: pointer;
}

// Cards that show summary of a data
.summary-cards {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.card-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(95% / 4);
  min-width: 210px;
  max-width: 350px;
  height: 100px;
  padding: 15px 20px;
  margin-bottom: 15px;
  margin-right: 5px;
  background-color: white;
  border-radius: 10px;
  border-left: 5px solid $secondary;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  &.color-blind-card {
    border-left: 5px solid $color-blind;
    &.paused { border-color: $danger;}
  }

  &.paused { border-color: $danger;}
  
  .card-icon {
    opacity: 0.2;
    font-size: 25px;
  }
}

// General cards. Ex: tables
.card {
  border: none;
  border-radius: 10px;
  padding: 0 0.5rem;
  margin-top: 10px;
}


.loading {
  display: flex;
  mix-blend-mode: multiply;
  margin: 5% auto;

  &.absolute {
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 30%;
  }

  &.cat {
    width: 25%;
  }
}

.page-404 {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.page-404-translate {
  transition: all 0.2s ease;
  color: $primary;

  &:hover {
    color: $secondary;
  }
}

a.mapboxgl-ctrl-logo {
  display: none;
}

.esri-view .esri-view-surface {
  &::after {
    outline: none !important;
  }
}

.beta-agreement-text {
  cursor: pointer;
  color: $primary;

  &:hover {
      color: $secondary
  }
  &.color-blind-banner{
    color: $color-blind;
    &:hover {
      color: $color-blind;
    }
  }
}

.beta-banner {
  background-color: $gray;
  color: $primary;
  font-weight: 600;
  font-size: 14px;
  padding: 2px 10px;

  &.color-blind-banner{
    color: $color-blind;
  }

}

.mui-textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px 4px 0 4px;
  border: 1px solid $gray;

  &:hover {
    border-color: $secondary;
  }

  &:focus {
    outline-color: $primary;
  }

}

.darkmode .modal-content{
  background: #2F2F2F;
  color: white
}

.darkmode .trip-modal-destinations{
  background: #2F2F2F;
  color: white
}

.darkmode .btn-close{
  background-color: white
}

.mui-textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px 4px 0 4px;
  border: 1px solid $gray;

  &:hover {
    border-color: $secondary;
  }

  &:focus {
    outline-color: $primary;
  }
}